import { useState } from "react";
import { enterName as postEnterName } from "../outlets/enterName";
import { User } from "../stores/appState";

export const useEnterName = () => {
  const [status, setStatus] = useState("");
  const [user, setUser] = useState<User | undefined>(undefined);
  const [message, setMessage] = useState<string | null>(null);

  const enterName = async (code: string, name: string) => {
    setStatus("loading");

    const res = await postEnterName(code, name);
    setStatus(res.status);
    if (res.data) {
      setUser(res.data);
    }
    setMessage(res.message ?? null);
  };

  return { status, enterName, message, user };
};
