import { useEffect, useState } from "react";
import { useAppStateStore } from "../stores/appState";
import { randomInt, randomRange } from "../util/random";
import { post } from "../util/api";

const CoinFlipTask = () => {
  const appState = useAppStateStore((s) => s);

  const [coin, setCoin] = useState<1 | 0 | -1>(-1);

  const [numHeads, setNumHeads] = useState(0);

  const [goalNumHeads, setGoalNumHeads] = useState(-1);

  const [flipping, setFlipping] = useState(false);

  useEffect(() => {
    if (goalNumHeads !== -1) return;
    setGoalNumHeads(randomRange(3, 10));
  }, []);

  const flipCoin = () => {
    if (flipping) return;
    setFlipping(true);
    setTimeout(() => {
      const flip = randomInt(2);
      console.log(flip);
      setCoin(flip as 0 | 1);
      if (flip) {
        setNumHeads((n) => n + 1);
      }
      setFlipping(false);
    }, randomRange(2, 5) * 400);
  };

  useEffect(() => {
    if (numHeads !== goalNumHeads) return;
    setTimeout(async () => {
      await post("completeTask", {
        taskCode: appState.currentTask,
        code: appState.user?.code,
      });

      appState.setView("default");
    }, 1000);
  }, [numHeads]);

  const game = (
    <div>
      <h1>Flip heads {goalNumHeads} times</h1>

      <button
        className={`coin ${flipping ? "flipping" : ""}`}
        disabled={flipping}
        onClick={flipCoin}
      >
        {coin === -1 ? "Flip" : coin === 0 ? "Tails" : "Heads"}
      </button>

      <h2>
        {numHeads}/{goalNumHeads} flipped
      </h2>
    </div>
  );

  if (numHeads === goalNumHeads) return <h1>You win!</h1>;
  return game;
};

export default CoinFlipTask;
