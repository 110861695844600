import { post } from "../util/api";

export const reportBody = async (code: string) => {
  const res = await post("reportBody", {
    code,
    reportBody: true,
  });

  return res;
};
