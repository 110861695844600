import { ReactElement, useEffect, useState } from "react";
import { useAppStateStore } from "../stores/appState";
import ButtonsTask from "./ButtonsTask";
import CoinFlipTask from "./CoinFlipTask";
import SvepTask from "./SvepTask";

const Task = () => {
  const appState = useAppStateStore((s) => s);
  const currTask = useAppStateStore((s) => s.currentTask);

  const [taskView, setTaskView] = useState<ReactElement | null>(null);

  useEffect(() => {
    console.log(currTask);
    console.log(appState);
    if (!currTask) return;

    const taskType = appState.lastHeartbeat?.tasks.find(
      (t) => t.code === currTask
    )?.type;

    console.log(taskType);

    switch (taskType) {
      case "buttons":
        setTaskView(<ButtonsTask />);
        return;
      case "coin":
        setTaskView(<CoinFlipTask />);
        return;
      case "svep":
        setTaskView(<SvepTask />);
        return;
    }
  }, [appState]);

  const close = () => {
    appState.setView("default");
  };

  return (
    <div>
      {taskView}

      <button className={"btn closeBtn"} onClick={close}>
        X
      </button>
    </div>
  );
};

export default Task;
