import { useEffect, useState } from "react";
import { useAppStateStore } from "../stores/appState";

const IsImpostor = () => {
  const appState = useAppStateStore((s) => s);

  const [timer, setTimer] = useState(false);

  useEffect(() => {
    if (appState.user && !timer) {
      setTimer((t) => true);
    }
  }, [appState]);

  useEffect(() => {
    if (!timer) return;
    setTimeout(() => {
      appState.setView("default");
    }, 5000);
  }, [timer]);

  return (
    <div className="">
      <div className="isImpostor">
        {appState.user && (
          <div>
            You are{" "}
            {appState.user.impostor ? "" : <span className="isNot">not</span>}{" "}
            <span className={appState.user.impostor ? "isTheImp" : ""}>
              the impostor.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default IsImpostor;
