import { useEffect } from "react";
import { useAppStateStore } from "../stores/appState";

const Emergency = () => {
  const appState = useAppStateStore((state) => state);

  useEffect(() => {
    if (!appState.lastHeartbeat?.emergency) appState.setView("default");
  }, [appState]);
  return (
    <div className="center flexdown h-full sabotageWarning">
      <h1>EMERGENCY</h1>
      <h2>Go to emergency room!</h2>
    </div>
  );
};

export default Emergency;
