import { useEffect } from "react";
import { useAppStateStore } from "../stores/appState";

const DeadBody = () => {
  const appState = useAppStateStore((state) => state);

  useEffect(() => {
    if (!appState.lastHeartbeat?.reportBody) appState.setView("default");

    if (appState.lastHeartbeat?.voting) appState.setView("voting");
  }, [appState]);
  return (
    <div className="center flexdown h-full">
      <h1>DEAD BODY REPORTED</h1>
      <h2>Go to emergency room!</h2>
    </div>
  );
};

export default DeadBody;
