import { useEffect, useState } from "react";
import { useAppStateStore } from "../stores/appState";
import { useSimplePost } from "../hooks/useSimplePost";
import { getEjected } from "../outlets/getEjected";

const getTallyData = (data: any) => {
  if (!data || !data.tally) return;

  let l = [];

  const votes = Object.keys(data.tally)
    .filter((t) => t !== "skip")
    .map((k) => data.tally[k]);

  return {
    votes,
    skip: data.tally["skip"] ?? [],
  };
};

const Tally = () => {
  const appState = useAppStateStore((s) => s);

  const [tally, setTally] = useState(null);

  const { status, data, post } = useSimplePost(getEjected);

  useEffect(() => {
    post(appState.user?.code ?? "");
  }, []);

  const t = getTallyData(data);

  return (
    <div>
      <h1>Tallys</h1>

      {t?.votes.map((v) => (
        <div className="tally">
          <h3>{v.name}</h3>
          <div>{v.votes.map((n: string) => n)}</div>
        </div>
      ))}

      <pre>
        {JSON.stringify({ status })}
        {JSON.stringify(data)}
      </pre>
    </div>
  );
};

export default Tally;
