import { ChangeEvent, useEffect, useState } from "react";
import { useAppStateStore } from "../stores/appState";
import { useLogin } from "../hooks/useLogin";

const Login = () => {
  const [code, setCode] = useState<string>("");
  const appState = useAppStateStore((state) => state);

  const { status, login, user, message } = useLogin();

  useEffect(() => {
    if (status === "success" && user) {
      appState.setUser(user);
      if (!user.name) {
        appState.setView("enterName");
      } else {
        appState.setView("lobby");
      }
    }
  }, [status, user]);

  const updateCode = (event: ChangeEvent<HTMLInputElement>) =>
    setCode(event.target.value);

  return (
    <div>
      <h1>Amongus SNERIKES VERSION</h1>
      <h2>Enter code</h2>
      <div className="centerX">
        <input
          className="input"
          type="text"
          value={code}
          onChange={updateCode}
        />
      </div>
      <div className="centerX">
        <button className="btn btn-lg" onClick={() => login(code)}>
          Log in
        </button>
      </div>
      <h2 className={status === "error" ? "error" : ""}>
        {status === "error" && message === "invalid_code" && "Wrong code"}
        {status === "error" &&
          message === "game_started" &&
          "Game already started"}
        {status === "loading" && "Loading..."}
      </h2>
    </div>
  );
};

export default Login;
