import { useEffect, useState } from "react";
import { useAppStateStore } from "../stores/appState";
import { randomInt, randomRange } from "../util/random";
import { post } from "../util/api";

const SvepTask = () => {
  const appState = useAppStateStore((s) => s);
  const complete = async () => {
    await post("completeTask", {
      taskCode: appState.currentTask,
      code: appState.user?.code,
    });

    appState.setView("default");
  };

  const game = (
    <div>
      <h1>Svep en öl</h1>

      <button className="btn btn-lg" onClick={complete}>
        Jag intygar på heder och samvete att jag har sänkt en öl
      </button>
    </div>
  );

  return game;
};

export default SvepTask;
