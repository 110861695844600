import { useState } from "react";
import { post } from "../util/api";

export const useSabotage = (code: string | undefined) => {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const sabotage = async (locationCode: string) => {
    setStatus("loading");

    const res = await post("sabotage", { code, locationCode });
    setStatus(res.status);
    setMessage(res.message ?? null);
  };

  const reset = () => {
    setStatus("");
    setMessage(null);
  };

  return { status, sabotage, message, reset };
};
