import { useEffect, useState } from "react";
import { useSimplePost } from "../hooks/useSimplePost";
import { getEjected } from "../outlets/getEjected";
import { useAppStateStore } from "../stores/appState";
import { start } from "repl";

const Ejected = () => {
  const appState = useAppStateStore((state) => state);
  const { status, post, data } = useSimplePost(getEjected);

  const [startType, setStartType] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    if (status === "") post("");
  }, []);

  useEffect(() => {
    if (appState.lastHeartbeat && !appState.lastHeartbeat.votingEnded) {
      appState.setView("default");
    }
  }, [appState]);

  useEffect(() => {
    if (data && data.name && !startType) {
      setStartType(true);
    }
  }, [data]);

  const full = `${data?.name ?? ""} was Ejected. ${data?.name ?? ""} was ${
    data?.impostor ? "" : "not"
  } the impostor.`;
  useEffect(() => {
    if (!startType) return;

    setTimeout(() => {
      setName((n) => n + full.charAt(n.length));
    }, 200);
  }, [name, startType]);

  return (
    <div>
      <h1>Results are in...</h1>
      <h2>{name}</h2>

      {name.startsWith(full) &&
        (!appState.lastHeartbeat?.win ? (
          <button
            className="btn btn-lg btn-bg"
            onClick={() => appState.setView("default")}
          >
            Go back
          </button>
        ) : (
          <h2>Civilians win</h2>
        ))}
    </div>
  );
};

export default Ejected;
