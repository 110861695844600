import { post } from "../util/api";

export const getUsers = async (code: string, alive: boolean = true) => {
  const res = await post("getUsers", {
    code,
    alive,
  });

  return res.status === "success"
    ? {
        ...res,
        data: {
          ...res.data,
          users: res.data.users.filter((u: any) => u.code !== code),
        },
      }
    : res;
};
