type HTTPMethod = "POST";

export const request = async (
  method: HTTPMethod,
  action: string,
  body: object
) => {
  const res = await fetch(process.env.REACT_APP_API_URL || "/", {
    method,
    body: JSON.stringify({ ...body, action }),
  });

  return await res.json();
};

export const post = async (action: string, body: object) =>
  await request("POST", action, body);
