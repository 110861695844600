import { useEffect, useState } from "react";
import { login as postLogin } from "../outlets/login";
import { reportBody as postReportBody } from "../outlets/reportBody";

export const useSimplePost = (postFunc: (code: string) => Promise<any>) => {
  const [status, setStatus] = useState("");
  const [data, setData] = useState<any>(null);

  const post = async (code: string) => {
    setStatus("loading");

    const res = await postFunc(code);
    setData(res.data);
    setStatus(res.status);
  };

  return { status, data, post };
};
