import { useAppStateStore } from "../stores/appState";

const CivilianWinScreen = () => {
  const appState = useAppStateStore((s) => s);

  return (
    <div>
      <h1>Civilians win.</h1>
      <button
        className="btn btn-lg btn-bg"
        onClick={() => appState.setView("login")}
      >
        Back to login
      </button>
    </div>
  );
};

export default CivilianWinScreen;
