import { useEffect, useReducer, useState } from "react";
import { useAppStateStore } from "../stores/appState";
import { useReportBody } from "../hooks/useReportBody";
import { post } from "../util/api";
import { useSabotage } from "../hooks/useSabotage";
import { useDisableSabotage } from "../hooks/useDisableSabotage";

const Home = () => {
  const appState = useAppStateStore((state) => state);

  const [deadModal, setDeadModal] = useState(false);

  const tasks = appState.lastHeartbeat?.tasks;

  const [enterTaskCode, setEnterTaskCode] = useState(false);
  const [enterSabotageCode, setEnterSabotageCode] = useState(false);
  const [enterDisableSabotageCode, setEnterDisableSabotageCode] =
    useState(false);
  const startTask = () => setEnterTaskCode(true);
  const startSabotage = () => setEnterSabotageCode(true);

  const [taskError, setTaskError] = useState("");

  const [taskCode, setTaskCode] = useState("");
  const [sabotageCode, setSabotageCode] = useState("");
  const [disableSabotageCode, setDisableSabotageCode] = useState("");

  const {
    status: sabotageStatus,
    sabotage: doSabotage,
    message: sabotageMessage,
    reset: resetSabotage,
  } = useSabotage(appState.user?.code);

  const {
    status: disableSabotageStatus,
    disableSabotage,
    message: disableSabotageMessage,
    reset: resetDisableSabotage,
  } = useDisableSabotage(appState?.user?.code);

  const completed = tasks?.filter((t) => t.completed);
  const remaining = tasks?.filter((t) => !t.completed);

  const doStartSabotage = async () => {
    await doSabotage(sabotageCode);
  };

  const commitSuicide = async (code: string | undefined) => {
    if (!code) return;
    setDeadModal(false);
    await post("suicide", { code });
    appState.setView("dead");
  };

  useEffect(() => {
    if (sabotageStatus !== "success") return;

    setTimeout(() => {
      resetSabotage();
      setEnterSabotageCode(false);
      setSabotageCode("");
    }, 2000);
  }, [sabotageStatus]);

  useEffect(() => {
    if (disableSabotageStatus !== "success") return;

    setTimeout(() => {
      resetDisableSabotage();
      setEnterDisableSabotageCode(false);
      setDisableSabotageCode("");
    }, 2000);
  }, [disableSabotageStatus]);

  const doStartTask = () => {
    console.log(
      appState.lastHeartbeat?.tasks.find(
        (t) => !t.completed && t.code === taskCode
      )
    );
    if (
      !appState.lastHeartbeat?.tasks.find(
        (t) => !t.completed && t.code === taskCode
      )
    ) {
      setTaskError("Task not available");
      return;
    }

    appState.setCurrentTask(taskCode);
    setEnterTaskCode(false);
    appState.setView("task");
  };

  useEffect(() => {
    if (appState.lastHeartbeat?.reportBody) {
      appState.setView("deadbody");
    }

    if (appState.lastHeartbeat?.emergency) {
      appState.setView("emergency");
    }

    if (appState.lastHeartbeat?.voting) {
      appState.setView("voting");
    }

    if (appState.lastHeartbeat && !appState.lastHeartbeat?.alive) {
      appState.setView("dead");
    }

    if (appState.lastHeartbeat?.gameOver) {
      appState.setView("gameOver");
    }

    if (appState.lastHeartbeat?.win) {
      appState.setView("win");
    }
  }, [appState]);

  const tasksHtml = completed?.map((t, i) => (
    <div key={i} className="task taskCompleted">
      {t.name}
    </div>
  ));

  const remainingHtml = remaining?.map((t, i) => (
    <div key={i} className="task taskRemaining">
      {t.name}
    </div>
  ));

  const taskButtonHTML = (
    <button
      className="btn btn-lg btn-bg"
      onClick={startTask}
      disabled={
        appState.lastHeartbeat?.tasks.filter((t) => !t.completed).length === 0
      }
    >
      Do task
    </button>
  );

  const sabotageButtonHTML = (
    <button className="btn btn-lg btn-bg" onClick={startSabotage}>
      Sabotage
    </button>
  );

  const { status: reportStatus, reportBody } = useReportBody();
  return (
    <div>
      <h1 className="userName">{appState.user?.name}</h1>

      {!appState.user?.impostor && (
        <div className="tasksList">
          <h2>Tasks</h2>
          {tasksHtml} {remainingHtml}
        </div>
      )}

      <div className="centerX">
        <button
          className="btn btn-lg"
          disabled={reportStatus != ""}
          onClick={() => reportBody(appState.user?.code ?? "")}
        >
          {reportStatus === "" && "Report body"}
          {reportStatus === "loading" && "Loading..."}
          {reportStatus === "success" && "Body reported"}
          {reportStatus === "error" && "Error..."}
        </button>
      </div>

      <div className="centerX">
        {appState.user?.impostor ? (
          !appState.lastHeartbeat?.sabotage ? (
            sabotageButtonHTML
          ) : (
            <h3>Sabotage in progress</h3>
          )
        ) : (
          taskButtonHTML
        )}
      </div>
      {enterTaskCode && (
        <div className="modal">
          <button
            className="btn closeBtn"
            onClick={() => setEnterTaskCode(false)}
          >
            X
          </button>
          <div className="modalContent">
            <h2>Enter task code</h2>
            <input
              type="text"
              value={taskCode}
              className="input"
              onChange={(e) => setTaskCode(e.target.value)}
            />
            <button className="btn btn-bg" onClick={doStartTask}>
              Start task
            </button>
            {taskError && <h3>{taskError}</h3>}
          </div>
        </div>
      )}

      {deadModal && (
        <div className="modal">
          <button className="btn closeBtn" onClick={() => setDeadModal(false)}>
            X
          </button>
          <div className="modalContent">
            <h2>Did you die?</h2>

            <button
              className="btn btn-lg btn-red"
              onClick={() => commitSuicide(appState.user?.code)}
            >
              Yes
            </button>
            <button className="btn btn-lg" onClick={() => setDeadModal(false)}>
              No
            </button>
          </div>
        </div>
      )}

      {enterSabotageCode && (
        <div className="modal">
          <button
            className="btn closeBtn"
            onClick={() => setEnterSabotageCode(false)}
          >
            X
          </button>
          <div className="modalContent">
            <h2>Enter sabotage code</h2>
            <input
              type="text"
              className="input"
              value={sabotageCode}
              onChange={(e) => setSabotageCode(e.target.value)}
            />

            <button
              className="btn btn-bg"
              onClick={doStartSabotage}
              disabled={sabotageStatus !== ""}
            >
              {sabotageStatus === "loading" ? "Loading..." : "Start sabotage"}
            </button>
            {sabotageMessage === "loc_not_found" && <h3>Location not found</h3>}
            {sabotageMessage === "loc_not_active" && (
              <h3>Location not active.</h3>
            )}
            {sabotageStatus === "success" && <h3>Sabotage successfull</h3>}
          </div>
        </div>
      )}

      {enterDisableSabotageCode && (
        <div className="modal">
          <button
            className="btn closeBtn"
            onClick={() => setEnterDisableSabotageCode(false)}
          >
            X
          </button>
          <div className="modalContent">
            <h2>Enter disable sabotage code</h2>
            <input
              type="text"
              className="input"
              value={disableSabotageCode}
              onChange={(e) => setDisableSabotageCode(e.target.value)}
            />

            <button
              className="btn btn-bg"
              disabled={disableSabotageStatus !== ""}
              onClick={() => disableSabotage(disableSabotageCode)}
            >
              {disableSabotageStatus === "loading"
                ? "Loading..."
                : "Start disable"}
            </button>
            {disableSabotageMessage === "loc_not_found" && (
              <h3>Location not found</h3>
            )}
            {disableSabotageMessage === "loc_not_active" && (
              <h3>Location not active.</h3>
            )}
            {disableSabotageStatus === "success" && (
              <h3>Disable successfull</h3>
            )}
          </div>
        </div>
      )}

      {!appState.user?.impostor && (
        <button className="btn btn-lg" onClick={() => setDeadModal(true)}>
          I died
        </button>
      )}

      {appState.lastHeartbeat?.sabotage && !appState.user?.impostor && (
        <button
          className="sabotageWarning btn"
          onClick={() => setEnterDisableSabotageCode(true)}
        >
          Sabotage: {appState.lastHeartbeat?.sabotage}{" "}
          {appState?.lastHeartbeat?.timeRemaining} s left
        </button>
      )}

      <div className="impostors">
        {appState.user?.impostor && <h2>Impostors</h2>}
        <div className="centerX">
          {appState.user?.impostor &&
            appState?.lastHeartbeat?.impostors?.map((i) => i.name).join(", ")}
        </div>
      </div>
    </div>
  );
};

export default Home;
