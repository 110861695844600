import { post } from "../util/api";

export const enterName = async (code: string, name: string) => {
  const res = await post("enterName", {
    code,
    name,
  });

  return res;
};
