import { useAppStateStore } from "../stores/appState";

const ImpostorWinScreen = () => {
  const appState = useAppStateStore((s) => s);

  return (
    <div>
      <h1>Game over. Impostors win</h1>
      <h2>The imposters were:</h2>
      {appState.lastHeartbeat?.impostors?.map((i) => (
        <h3>{i.name}</h3>
      ))}
      <button
        className="btn btn-lg btn-bg"
        onClick={() => appState.setView("login")}
      >
        Back to login
      </button>
    </div>
  );
};

export default ImpostorWinScreen;
