import { useEffect, useState } from "react";
import { useSimplePost } from "../hooks/useSimplePost";
import { getUsers } from "../outlets/getUsers";
import { useAppStateStore } from "../stores/appState";
import { useVote } from "../hooks/useVote";

const VotingOld = () => {
  const appState = useAppStateStore((state) => state);
  const { status: ulStatus, data, post: getUserList } = useSimplePost(getUsers);

  const [votingStarted, setVotingStarted] = useState(false);

  const { status: voteStatus, vote, message } = useVote();

  const [skip, setSkip] = useState(false);

  const [voteCode, setVoteCode] = useState<string | null>(null);

  useEffect(() => {
    getUserList(appState.user?.code ?? "");
  }, []);

  useEffect(() => {
    if (appState.lastHeartbeat?.voting) {
      setVotingStarted(true);
    } else if (appState.lastHeartbeat?.votingEnded) {
      appState.setView("tally");
    }
  }, [appState]);

  const voteSkip = () => {
    setVoteCode((v) => (skip ? v : null));
    setSkip((s) => !s);
  };

  const chooseVote = (code: string) => () => {
    setVoteCode(code);
    setSkip(false);
  };

  const voteList = data?.users.map((u: any, i: number) => (
    <button
      key={i}
      className={`btn btn-lg ${voteCode === u.code ? "voted-btn" : ""}`}
      onClick={chooseVote(u.code)}
    >
      {u.name}
    </button>
  ));

  return (
    <div>
      <h1>Vote out the impostor...</h1>

      {voteStatus !== "success" && voteList}
      {voteStatus !== "success" && (
        <button
          className={`btn btn-lg ${skip ? "voted-btn" : ""}`}
          onClick={voteSkip}
        >
          Skip
        </button>
      )}

      {ulStatus === "loading" && <h2>Loading...</h2>}

      <button
        className="btn btn-lg btn-bg"
        onClick={() => vote(appState.user?.code ?? "", voteCode ?? "", skip)}
      >
        Vote
      </button>
      {voteStatus === "loading" && <h2>Loading</h2>}
      {voteStatus === "success" && <h2>Vote cast</h2>}
    </div>
  );
};

const Voting = () => {
  const appState = useAppStateStore((s) => s);

  useEffect(() => {
    if (appState.lastHeartbeat?.votingEnded) {
      appState.setView("ejected");
    }
  }, [appState]);

  return <h1>Vote out the impostor..</h1>;
};

export default Voting;
