import { useEffect, useState } from "react";
import { postHeartbeat } from "../outlets/heartbeat";
import { Heartbeat } from "../stores/appState";

const useHeartBeat = (code: string | undefined) => {
  const [lastHeartbeat, setLastHeartbeat] = useState<Heartbeat | null>(null);

  useEffect(() => {
    console.log("here");
    const interval = setInterval(async () => {
      if (!code) return;
      const res = await postHeartbeat(code);
      setLastHeartbeat(res.data);
    }, 5000);

    return () => clearInterval(interval);
  }, [code]);

  return lastHeartbeat;
};

export default useHeartBeat;
