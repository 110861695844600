import { useState } from "react";
import { post } from "../util/api";
import { Task } from "../stores/appState";

export const useUpdateTasks = () => {
  const [status, setStatus] = useState("");
  const [tasks, setTasks] = useState(null);
  const [message, setMessage] = useState("");

  const updateTasks = async (tasks: Task[]) => {
    setStatus("loading");

    const res = await post("setTasks", { tasks });
    setStatus(res.status);
    if (res.status === "error") {
      setMessage(res.message);
    } else {
      setTasks(res.data.tasks);
    }
  };

  return { status, updateTasks, tasks, message };
};
