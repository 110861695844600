import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { useAppStateStore } from "./stores/appState";
import Login from "./views/Login";
import ViewBase from "./views/ViewBase";
import Lobby from "./views/Lobby";
import useHeartBeat from "./hooks/useHeartBeat";
import Home from "./views/Home";
import DeadBody from "./views/DeadBody";
import Voting from "./views/Voting";
import Ejected from "./views/Ejected";
import EnterName from "./views/EnterName";
import Dead from "./views/Dead";
import { Route, Routes, useSearchParams } from "react-router-dom";
import Admin from "./views/Admin";
import Task from "./views/Task";
import IsImpostor from "./views/IsImpostor";
import CivilianWinScreen from "./views/CivilianWinScreen";
import ImpostorWinScreen from "./views/ImpostorWinScreen";
import Tally from "./views/Tally";
import VotingControl from "./views/VotingControl";
import HubView from "./views/HubView";
import Emergency from "./views/Emergency";

const Game = () => {
  const appState = useAppStateStore((state) => state);

  const heartbeat = useHeartBeat(appState.user?.code);

  useEffect(() => {
    if (heartbeat) appState.setLastHeartbeat(heartbeat);

    if (
      appState.user &&
      heartbeat?.impostors?.find((u) => u.code === appState.user?.code)
    ) {
      appState.setUser({ ...appState.user, impostor: true });
    }
  }, [heartbeat]);

  useEffect(() => {
    if (appState.lastHeartbeat?.gameStarted) return;
    if (
      appState.view === "login" ||
      appState.view === "lobby" ||
      appState.view === "enterName"
    )
      return;

    appState.setView("login");
  }, [appState]);

  return (
    <ViewBase>
      {appState.view === "login" && <Login />}
      {appState.view === "lobby" && <Lobby />}
      {appState.view === "isImpostor" && <IsImpostor />}
      {appState.view === "default" && <Home />}
      {appState.view === "deadbody" && <DeadBody />}
      {appState.view === "emergency" && <Emergency />}
      {appState.view === "voting" && <Voting />}
      {appState.view === "ejected" && <Ejected />}
      {appState.view === "dead" && <Dead />}
      {appState.view === "enterName" && <EnterName />}
      {appState.view === "win" && <CivilianWinScreen />}
      {appState.view === "gameOver" && <ImpostorWinScreen />}
      {appState.view === "task" && <Task />}
      {appState.view === "tally" && <Tally />}
    </ViewBase>
  );
};

function App() {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("p");
  return (
    <>
      {/* // <Routes> */}
      {page === "hub" && <HubView />}
      {page === "admin" && <Admin />}
      {!page && <Game />}
      {/* <Route path="/" element={<Game />}></Route> */}
      {/* <Route path="/admin" element={<Admin />}></Route> */}
      {/* <Route path="/voting" element={<VotingControl />}></Route> */}
      {/* <Route path="/hub" element={<HubView />}></Route> */}
      {/* </Routes> */}
    </>
  );
}

export default App;
