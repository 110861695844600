import { ChangeEvent, useEffect, useState } from "react";
import { User } from "../stores/appState";
import { getUsers } from "../outlets/getUsers";
import { useUpdateUsers } from "../hooks/useUpdateUsers";
import { post } from "../util/api";
import { useUpdateTasks } from "../hooks/useUpdateTasks";

export type GameState = {
  emergency?: boolean;
  reportBody?: boolean;
  voting?: boolean;
  votingEnded?: boolean;
  gameEnded?: boolean;
  gameStarted?: boolean;
  users?: User[];
};

const Admin = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [gameState, setGameState] = useState<GameState>({});

  const [tasksPerUser, setTasksPerUser] = useState(0);
  const [userTasks, setUserTasks] = useState<any>({});

  const [tasks, setTasks] = useState<any[]>([]);

  const [redist, setRedist] = useState(false);

  const { updateUsers, status: updateStatus } = useUpdateUsers();
  const { updateTasks, status: taskStatus } = useUpdateTasks();

  const fetchUsers = async () => {
    const res = await getUsers("admin", false);
    console.log(res);
    setUsers(res.data.users);
  };

  const updateUser =
    (i2: number, prop: keyof User, bool: boolean = false) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      console.log(i2, prop, event.target.value);
      const val = bool ? !users[i2][prop] : event.target.value;
      setUsers(users.map((u, i) => (i === i2 ? { ...u, [prop]: val } : u)));
    };

  const updateTask =
    (i2: number, prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setTasks((t: any) =>
        t.map((t2: any, i: number) =>
          i === i2 ? { ...t2, [prop]: event?.target.value } : t2
        )
      );
    };

  const updateAllUsersAndUpdate = async () => {
    console.log("first");
    console.log(users);
    await updateUsers(users);
    await fetchUsers();
    await fetchTasks();
  };

  const updateAllTasksAndUpdate = async () => {
    await updateTasks(tasks);
    await fetchUsers();
    await fetchTasks();
  };

  const addUser = () => {
    setUsers((u) => [
      ...u,
      { name: "", code: "", impostor: false, alive: false, active: false },
    ]);
  };

  const delUser = (i: number) => () => {
    setUsers((us) => us.filter((u, i2) => i !== i2));
  };

  const fetchTasks = async () => {
    const res = await post("getUserTasks", {});

    if (res.data) {
      setUserTasks(res.data.tasks);
    }
  };

  const fetchAllTasks = async () => {
    const res = await post("getAllTasks", {});
    if (res.data) {
      setTasks(res.data.tasks);
    }
  };

  const addTask = () => {
    setTasks((t) => [...t, { name: "", type: "", code: "" }]);
  };

  const fetchGameState = async () => {
    const res = await post("getGameState", {});
    if (res.data) {
      setGameState(res.data);
    }
  };

  const stopStartGame = async () => {
    await post("startGame", {
      start: !gameState.gameStarted,
      tasksPerUser: tasksPerUser ?? 1,
    });
    await fetchGameState();
  };

  useEffect(() => {
    fetchUsers();
    fetchAllTasks();
    fetchTasks();
    fetchGameState();
  }, []);

  const redistribute = async () => {
    setRedist(true);
    await post("redistributeTasks", { tasksPerUser });
    setRedist(false);
    fetchTasks();
  };

  const usersHTML = (
    <div className="admin container">
      <h2>Users</h2>
      <div className="flex flex-table fl-strict">
        <div>Code</div>
        <div>Name</div>
        <div>Impostor?</div>
        <div>Alive?</div>
        <div>Active?</div>
        <div>Delete</div>
      </div>
      {users.map((u, i) => (
        <div key={i} className="flex fl-strict">
          <input
            type="text"
            className="input"
            value={u.code}
            onChange={updateUser(i, "code")}
          />
          <input
            type="text"
            className="input"
            value={u.name}
            onChange={updateUser(i, "name")}
          />
          <div>
            <input
              type="checkbox"
              checked={u.impostor}
              onChange={updateUser(i, "impostor", true)}
            />
          </div>
          <div>
            <input
              type="checkbox"
              checked={u.alive}
              onChange={updateUser(i, "alive", true)}
            />
          </div>
          <div>
            <input
              type="checkbox"
              checked={u.active}
              onChange={updateUser(i, "active", true)}
            />
          </div>
          <button className="btn btn-lg btn-bg" onClick={delUser(i)}>
            Delete{" "}
          </button>
        </div>
      ))}
      <div className="flex">
        <button className="btn btn-lg btn-bg" onClick={updateAllUsersAndUpdate}>
          Update users
        </button>
        <button className="btn btn-lg btn-green" onClick={addUser}>
          Add user
        </button>
      </div>

      <h2>Tasks</h2>
      {Object.keys(userTasks).map((key: string) => (
        <>
          <h3>Tasks for {users.find((u) => u.code === key)?.name ?? ""}</h3>
          <div className="flex tasklist">
            <div>Code</div>
            <div>Name</div>
            <div>Type</div>
            <div>Completed?</div>
          </div>
          {userTasks[key].map((task: any) => (
            <div className="flex tasklist">
              <div>{task.code}</div>
              <div>{task.name}</div>
              <div>{task.type}</div>
              <input type="checkbox" checked={task.completed} />
            </div>
          ))}
        </>
      ))}
      <h2>Redistribute tasks? {redist ? "..." : ""}</h2>
      <div className="flex">
        <input
          className="input"
          type="number"
          placeholder="Tasks per user"
          value={tasksPerUser}
          onChange={(e) => setTasksPerUser(parseInt(e.target.value))}
        />
        <button className="btn btn-lg btn-bg" onClick={redistribute}>
          Redistribute
        </button>
      </div>

      <h2>Tasks</h2>
      <div className="flex">
        <div>Code</div>
        <div>Name</div>
        <div>Type</div>
      </div>
      {tasks.map((t: any, i) => (
        <div className="flex">
          <input
            className="input"
            type="text"
            value={t.code}
            onChange={updateTask(i, "code")}
          />
          <input
            className="input"
            type="text"
            value={t.name}
            onChange={updateTask(i, "name")}
          />
          <input
            className="input"
            type="text"
            value={t.type}
            onChange={updateTask(i, "type")}
          />
          <button
            className="btn btn-bg"
            onClick={() => setTasks((t) => t.filter((t2, i2) => i2 !== i))}
          >
            Delete
          </button>
        </div>
      ))}
      <div className="flex">
        <button className="btn btn-lg" onClick={updateAllTasksAndUpdate}>
          Update tasks
        </button>
        <button className="btn btn-lg btn-green" onClick={addTask}>
          Add task
        </button>
      </div>
      <button
        className={`btn btn-lg ${
          gameState.gameStarted ? "btn-red" : "btn-green"
        }`}
        onClick={stopStartGame}
      >
        {gameState.gameStarted ? "Stop" : "Start"} game
      </button>
    </div>
  );

  return <>{usersHTML}</>;
};

export default Admin;
