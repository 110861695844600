import { useEffect, useState } from "react";
import { post } from "../util/api";
import { useAppStateStore } from "../stores/appState";
import { randomInt } from "../util/random";

const ButtonsTask = () => {
  const appState = useAppStateStore((s) => s);
  const [buttons, setButtons] = useState<boolean[]>(
    Array.from({ length: 16 }, (e) => false)
  );

  const [ev, setEv] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setEv((ev) => ev + 1);
    }, randomInt(6) * 600);
    if (ev === 0) {
      return;
    }
    const on = buttons.map((b, i) => ({ b, i })).filter((b) => b.b);
    if (on.length === buttons.length || on.length === 0) {
      return;
    }

    const idx = on[randomInt(on.length)].i;
    setButtons((b) => b.map((b, i) => (i === idx ? false : b)));

    console.log("ev");
  }, [ev]);

  const setTaskCompleted = async () => {
    await post("completeTask", {
      taskCode: appState.currentTask,
      code: appState.user?.code,
    });

    appState.setView("default");
  };

  useEffect(() => {
    if (buttons.filter((b) => b).length !== buttons.length) return;

    // task completed.
    setTaskCompleted();
  }, [buttons]);

  return (
    <div>
      <h1>Fill in the field</h1>
      <div className="gameBtns">
        {buttons.map((b, i) => (
          <button
            key={i}
            className={`gameBtn ${b ? "gameBtnOn" : "gameBtnOff"}`}
            onClick={() =>
              setButtons((b) => b.map((bt, i2) => (i2 === i ? !bt : bt)))
            }
          ></button>
        ))}
      </div>
    </div>
  );
};

export default ButtonsTask;
