import { useState } from "react";
import { post } from "../util/api";

export const useDisableSabotage = (code: string | undefined) => {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const disableSabotage = async (locationCode: string) => {
    setStatus("loading");

    const res = await post("disableSabotage", { code, locationCode });

    console.log("setting status");
    setStatus(res.status);
    setMessage(res.message ?? null);
  };

  const reset = () => {
    setStatus("");
    setMessage(null);
  };

  return { status, disableSabotage, message, reset };
};
