import { create } from "zustand";

type AppView =
  | "login"
  | "lobby"
  | "isImpostor"
  | "tally"
  | "enterName"
  | "default"
  | "emergency"
  | "deadbody"
  | "voting"
  | "dead"
  | "task"
  | "gameOver"
  | "win"
  | "ejected";

export type TaskType = "buttons" | "coin" | "svep";

export type Task = {
  code: string;
  name: string;
  completed: boolean;
  type: TaskType;
};

export type Heartbeat = {
  emergency: boolean;
  alive: boolean;
  reportBody: boolean;
  voting: boolean;
  votingEnded: boolean;
  gameStarted: boolean;
  tasks: Task[];
  impostors?: User[];
  sabotage?: string;
  timeRemaining?: number;
  win?: boolean;
  gameOver?: boolean;
};

export type User = {
  code: string;
  name: string;
  alive: boolean;
  isAlive?: boolean;
  active: boolean;
  impostor: boolean;
};

export type AppState = {
  view: AppView;
  user?: User;
  currentTask?: string;
  lastHeartbeat?: Heartbeat;
  setView: (view: AppView) => void;
  setUser: (user: User) => void;
  setCurrentTask: (task: string) => void;
  setLastHeartbeat: (heartbeat: Heartbeat) => void;
};

export const useAppStateStore = create<AppState>((set) => ({
  view: "login",
  lastHeartbeat: undefined,
  currentTask: undefined,
  setView: (view: AppView) => set((state) => ({ ...state, view })),
  setCurrentTask: (currentTask: string) =>
    set((state) => ({ ...state, currentTask })),
  setUser: (user: User) => set((state) => ({ ...state, user })),
  setLastHeartbeat: (lastHeartbeat: Heartbeat) =>
    set((state) => ({ ...state, lastHeartbeat })),
}));
