import { ChangeEvent, useEffect, useState } from "react";
import { useAppStateStore } from "../stores/appState";
import { useLogin } from "../hooks/useLogin";
import { useEnterName } from "../hooks/useEnterName";

const EnterName = () => {
  const [name, setName] = useState<string>("");
  const appState = useAppStateStore((state) => state);

  const { status, enterName, message, user } = useEnterName();

  useEffect(() => {
    if (status === "success") {
      appState.setView("lobby");
      if (user) {
        appState.setUser(user);
      }
    }
  }, [status]);

  const updateName = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);

  return (
    <div>
      <h2>Enter name</h2>
      <h2 className={status === "error" ? "error" : ""}>
        {status === "error" &&
          message === "name_taken" &&
          "Name taken, try another one"}

        {status === "error" && message === "no_name" && "Please enter a name"}
        {status === "loading" && "Loading..."}
      </h2>
      <div className="centerX">
        <input
          className="input"
          type="text"
          value={name}
          onChange={updateName}
        />
      </div>
      <div className="centerX">
        <button
          className="btn btn-lg"
          onClick={() => enterName(appState?.user?.code ?? "", name)}
        >
          Let's go!
        </button>
      </div>
    </div>
  );
};

export default EnterName;
