import { useEffect } from "react";
import { useAppStateStore } from "../stores/appState";

const Dead = () => {
  const appState = useAppStateStore((s) => s);

  return (
    <div>
      <h1>You are dead...</h1>
      <button
        className="btn btn-lg btn-bg"
        onClick={() => appState.setView("login")}
      >
        Back to login
      </button>
    </div>
  );
};

export default Dead;
