import { useEffect, useState } from "react";
import { useAppStateStore } from "../stores/appState";
import { GameState } from "./Admin";
import { post } from "../util/api";

const LoadingView = () => <h1>Loading...</h1>;

const StartGameView = ({}) => {
  const [tasks, setTasks] = useState<number>(3);
  const [imps, setImps] = useState<number>(1);
  const [started, setStarted] = useState(false);
  const startGame = async () => {
    setStarted(true);
    await post("startGame", {
      start: true,
      tasksPerUser: tasks,
      numImpostors: imps,
    });
  };

  return (
    <div>
      <button className="megaBtn" onClick={startGame} disabled={started}>
        {started ? "Starting..." : "Start game"}
      </button>

      <h2>Tasks per user</h2>
      <div className="centerX">
        <input
          type="number"
          className="input"
          placeholder="Tasks per user"
          value={tasks}
          onChange={(e) => setTasks(parseInt(e.target.value))}
        />
      </div>
      <h2>Number of impostors</h2>
      <div className="centerX">
        <input
          type="number"
          className="input"
          placeholder="Number of impostors"
          value={imps}
          onChange={(e) => setImps(parseInt(e.target.value))}
        />
      </div>
    </div>
  );
};

const VotingView = ({ gameState }: { gameState: GameState }) => {
  const [user, setUser] = useState<undefined | string>(undefined);

  const [started, setStarted] = useState(false);

  const vote = async () => {
    const body = user ? { user } : { skip: true };

    setStarted(true);
    await post("ejectUser", body);
  };

  return (
    <div className="container">
      <h1>Vote out the impostor</h1>
      {/* <pre>{JSON.stringify(gameState)}</pre> */}

      {(gameState.users ?? []).map((u) => (
        <button
          className={`btn btn-lg vbtn ${user === u.code ? "voted-btn" : ""}`}
          onClick={() => setUser(u.code)}
          disabled={!u.isAlive}
        >
          {u.name} {u.isAlive ? "" : " (DEAD)"}
        </button>
      ))}
      <button
        className={`btn btn-lg vbtn ${user === undefined ? "voted-btn" : ""}`}
        onClick={() => setUser(undefined)}
      >
        Skip
      </button>

      <button
        className="btn btn-lg my-2 btn-red"
        onClick={vote}
        disabled={started}
      >
        VOTE
      </button>
    </div>
  );
};

const StartVotingView = () => {
  const [started, setStarted] = useState(false);

  const startVoting = async () => {
    setStarted(true);
    await post("emergency", { emergency: false });
    await post("startVoting", { voting: true });
  };

  return (
    <div className="container">
      <div className="centerX">
        <button className="megaBtn" onClick={startVoting} disabled={started}>
          {!started ? "Start voting" : "Starting..."}
        </button>
      </div>
    </div>
  );
};

const VotingEndedView = () => {
  const [started, setStarted] = useState(false);
  const endVote = async () => {
    setStarted(true);
    await post("endVoting", {});
  };
  return (
    <div className="container">
      <div className="centerX">
        <button className="megaBtn" onClick={endVote} disabled={started}>
          {started ? "Returning..." : "Return to game"}
        </button>
      </div>
    </div>
  );
};

const StopGame = () => {
  const [started, setStarted] = useState(false);
  const stop = async () => {
    setStarted(true);
    await post("startGame", { start: false });
  };
  return (
    <div>
      <div className="centerX">
        <button
          className="megaBtn megaEmergency"
          onClick={stop}
          disabled={started}
        >
          {started ? "Stopping" : "Stop game"}
        </button>
      </div>
    </div>
  );
};

const HubView = () => {
  const [gameState, setGameState] = useState<GameState>({});
  const [started, setStarted] = useState(false);

  const fetchGameState = async () => {
    const res = await post("getGameState", {});
    if (res.data) {
      setGameState(res.data);
    }
  };

  useEffect(() => {
    fetchGameState();
    const i = setInterval(() => {
      fetchGameState();
    }, 5000);

    return () => clearInterval(i);
  }, []);

  const emergency = async () => {
    setStarted(true);
    await post("emergency", { emergency: true });
  };

  if (!Object.keys(gameState).length) return <LoadingView />;

  if (!gameState.gameStarted) return <StartGameView />;

  if (gameState.gameEnded) return <StopGame />;

  if (gameState.reportBody || gameState.emergency) return <StartVotingView />;

  if (gameState.voting) return <VotingView gameState={gameState} />;

  if (gameState.votingEnded) return <VotingEndedView />;

  return (
    <div className="container">
      <div className="centerX">
        <button
          className="megaBtn megaEmergency"
          onClick={emergency}
          disabled={started}
        >
          {started ? "Calling crew..." : "EMERGENCY"}
        </button>
      </div>
    </div>
  );
};

export default HubView;
