import { useEffect, useState } from "react";
import { login as postLogin } from "../outlets/login";

export const useLogin = () => {
  const [status, setStatus] = useState("");
  const [user, setUser] = useState<any>(null);
  const [message, setMessage] = useState("");

  const login = async (code: string) => {
    setStatus("loading");

    const res = await postLogin(code);
    setUser(res.data);
    setStatus(res.status);
    if (res.status === "error") {
      console.log(res);

      setMessage(res.message);
    }
  };

  return { status, login, user, message };
};
