import { useEffect } from "react";
import { useAppStateStore } from "../stores/appState";

const Lobby = () => {
  const appState = useAppStateStore((state) => state);

  useEffect(() => {
    if (appState.lastHeartbeat?.gameStarted) appState.setView("isImpostor");
  }, [appState]);
  return (
    <div>
      <h1>Welcome {appState.user?.name}</h1>
      <h2>Waiting on game start...</h2>
    </div>
  );
};

export default Lobby;
