import { useEffect, useState } from "react";
import { login as postLogin } from "../outlets/login";
import { post } from "../util/api";
import { User } from "../stores/appState";

export const useUpdateUsers = () => {
  const [status, setStatus] = useState("");
  const [users, setUsers] = useState(null);
  const [message, setMessage] = useState("");

  const updateUsers = async (users: User[]) => {
    setStatus("loading");
    console.log("updating");
    console.log(users);

    const res = await post("setUsers", { users });
    setStatus(res.status);
    if (res.status === "error") {
      console.log(res);

      setMessage(res.message);
    } else {
      setUsers(res.data.users);
    }
  };

  return { status, updateUsers, users, message };
};
