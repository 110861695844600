import { useEffect, useState } from "react";
import { login as postLogin } from "../outlets/login";
import { reportBody as postReportBody } from "../outlets/reportBody";

export const useReportBody = () => {
  const [status, setStatus] = useState("");

  const reportBody = async (code: string) => {
    setStatus("loading");

    const res = await postReportBody(code);
    setStatus(res.status);
  };

  return { status, reportBody };
};
